.article
  display: flex
  position: relative
  flex-direction: column
  width: 100%
  max-width: 576px
  transition: $all

  +media(s)
    max-width: 100%

.article__link:before
  content: ''
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1

.article__title
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  font-family: 'Geomanist', 'Inter', sans-serif
  font-size: 40px
  line-height: 40px
  overflow: hidden

  +media(s)
    font-size: 32px
    line-height: 32px

.article__text
  margin-top: 16px
  font-size: 14px
  line-height: 20px
  opacity: 0.6
  transition: $all

.article__footer
  display: flex
  margin-top: 20px
  width: fit-content

  +media(s)
    flex-direction: column
    width: 100%

.article__info
  margin-right: 16px
  white-space: nowrap
  font-size: 12px
  line-height: 28px
  font-weight: 500

  +media(s)
    margin-right: 0
    margin-bottom: 16px
