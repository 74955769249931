.whitepaper
  width: 100%
  max-width: 412px
  border-radius: 14px
  color: $white
  background-color: $dark1
  background-image: url(/static/img/whitepaper-bg.jpg)
  background-size: cover
  background-position: center center
  overflow: hidden
  transition: $all

  &:hover,
  &:focus,
  &:focus-within

    .whitepaper__link:before
      background-color: #{$blue-start}0D

.whitepaper__wrapper
  display: flex
  position: relative
  flex-direction: column
  justify-content: space-between
  width: 100%
  height: 100%
  padding: 46px 30px 24px

  +media(s)
    padding: 30px 20px 16px

.whitepaper__header,
.whitepaper__footer
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

.whitepaper__body
  display: flex
  flex-direction: column
  width: 100%
  height: 100%

.whitepaper__image
  width: 100%
  max-width: 144px
  height: auto
  object-fit: contain

  +media(s)
    max-width: 96px

.whitepaper__version,
.whitepaper__title,
.whitepaper__link
  font-family: 'Geomanist', 'Inter', sans-serif
  font-weight: 500
  color: inherit
  text-align: left

.whitepaper__version
  font-family: 'Geomanist', 'Inter', sans-serif
  font-size: 14px
  line-height: 14px

  +media(s)
    font-size: 10px
    line-height: 10px

.whitepaper__title
  margin-top: 28px
  font-size: 42px
  line-height: 42px

  +media(s)
    font-size: 28px
    line-height: 28px

.whitepaper__link
  font-size: 12px
  line-height: 12px

  &:before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: transparent
    transition: $all
