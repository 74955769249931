.input-group
  display: flex
  align-items: center
  width: 100%
  margin-top: -14px
  border-bottom: 1px solid #{$white}33
  color: $white
  transition: $all

  &:hover,
  &:focus,
  &:focus-within
    border-color: $white
    color: $white

.input-group__icon
  width: 24px
  height: 24px

.input
  width: 100%
  height: 48px
  padding: 0 12px
  border: none
  font-size: 16px
  line-height: 20px
  vertical-align: middle
  color: $white
  background-color: transparent
  transition: $all
  outline: none

  &:placeholder
    color: $white
