.pagination
  display: flex
  flex-wrap: wrap
  width: fit-content
  max-width: 100%
  align-items: center
  column-gap: 10px
  row-gap: 10px

.pagination__button
  display: flex
  position: relative
  justify-content: center
  align-items: center
  width: 40px
  height: 40px
  border: 1px solid $dark2
  border-radius: 10px
  background-color: $dark1
  font-size: 14px
  line-height: 20px
  font-weight: 600
  color: $white
  outline: none
  transition: $all

  &:hover,
  &:focus
    background-color: $dark5
    border-color: $blue-end

  &:active
    background-color: $dark5
    color: $dolphin
    border-color: $pacific

.pagination__button--active
  background-color: $dark5
  border-color: $blue-end
