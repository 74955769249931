.latest-post
  display: flex
  position: relative
  width: 100%
  max-width: 980px
  height: 420px
  padding: 34px
  border-radius: 20px
  background-color: $dark1
  border: 1px solid $dark1
  overflow: hidden
  transition: $all

  +media(s)
    flex-direction: column
    max-width: 100%
    height: auto
    padding: 16px

.latest-post__image-container
  display: flex
  position: relative
  justify-content: center
  align-items: center
  width: 100%
  max-width: 280px
  height: 100%
  margin-right: 30px
  border-radius: 20px
  background-color: $dark5
  overflow: hidden

  +media(s)
    max-width: 100%
    height: 120px
    margin-right: 0

.latest-post__image
  position: absolute
  top: 50%
  left: 50%
  min-width: 100%
  width: 100%
  max-width: 100%
  min-height: 100%
  max-height: 100%
  object-fit: cover
  transform: translate(-50%, -50%) scale(1)
  transition: $all

.latest-post__content
  display: flex
  flex-direction: column
  width: 100%
  margin-top: 12px
  overflow: hidden

.latest-post__head
  display: flex
  align-items: center
  width: fit-content
  margin-bottom: 36px

  +media(s)
    flex-direction: column
    align-items: flex-start
    width: 100%
    max-width: 100%
    overflow: hidden
    margin-bottom: 24px

.latest-post__tags
  margin-right: 12px

  +media(s)
    margin-right: 0
    margin-bottom: 16px
    max-width: 100%

.latest-post__info
  font-size: 12px
  line-height: 16px
  font-weight: 500

.latest-post__link:before
  content: ''
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 2

.latest-post__title
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  font-family: 'Geomanist', 'Inter', sans-serif
  font-size: 60px
  line-height: 60px
  overflow: hidden

  +media(m)
    font-size: 32px
    line-height: 32px

.latest-post__text
  margin-top: 20px
  font-size: 14px
  line-height: 20px
  opacity: 0.6
