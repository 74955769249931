.tags
  display: flex
  flex-wrap: wrap
  column-gap: 10px
  row-gap: 10px
  width: fit-content

.tag
  display: flex
  justify-content: center
  align-items: center
  width: fit-content
  height: 28px
  padding: 0 10px
  border: 1px solid $dark5
  border-radius: 14px
  font-size: 12px
  line-height: 16px
  font-weight: 600
  background-color: $dark4
  color: $white
  transition: $all

  &:hover,
  &:focus
    border-color: $blue-end
  &:active
    background-color: $dark5
    color: $dolphin
    border-color: $pacific

.tag--active
  background-color: $blue-start
  border-color: $blue-start
  color: $white
