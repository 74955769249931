.navigate-button
  display: flex
  justify-content: center
  align-items: center
  width: fit-content
  height: 50px
  margin-right: auto
  padding: 0 15px
  border: none
  font-size: 16px
  line-height: 20px
  font-weight: 600
  color: $white
  background-color: transparent
  column-gap: 10px
  outline: none
  transition: $all

  &:hover,
  &:focus
    color: $blue-start
