.section--future
    margin-block: 100px
    +media(s)
        margin-top: 16px
        margin-bottom: 72px

    .hero
        &::after
            content: none
    .hero__title
        +media(s)
            font-family: Geomanist
            font-size: 60px
            font-weight: 400
            line-height: 60px
            letter-spacing: 0em
            text-align: center
            padding: 0 12px
    .hero__text
        +media(s)
            max-width: 100%
