.section--create
  margin-top: 238px
  margin-bottom: 60px

  &:after
    top: 50%
    left: 50%
    width: 1550px
    height: 1552px
    transform: translate(-50%, -15%) rotate(20deg)

  +media(s)
    margin-top: 74px
    margin-bottom: 68px

.create
  display: flex
  flex-direction: column
  width: 100%
  align-items: center

.create--articles
  margin-top: 106px

.create--article-page
  margin-top: 156px
  margin-bottom: 64px

.create__title
  font-size: 60px
  line-height: 60px

  +media(s)
    max-width: 200px
    font-size: 36px
    line-height: 36px

.create__button
  margin-top: 32px

  +media(s)
    margin-top: 20px

.create__text
  margin-top: 32px

  +media(s)
    margin-top: 24px
    font-size: 14px
