*
  box-sizing: border-box
  -webkit-font-smoothing: antialiased
  -ms-overflow-style: none
  scrollbar-width: none

  &::-webkit-scrollbar
    display: none

html
  font-family: 'Inter', sans-serif
  height: 100%
  overflow-x: hidden
  scroll-behavior: smooth
  overscroll-behavior-y: none

body
  position: relative
  min-height: 100%
  background-image: linear-gradient(180deg, #000E47, $dark1 calc(100% - 104px))
  background-color: $dark1
  background-size: 100% auto
  background-repeat: no-repeat
  background-position: center top
  color: $white
  scroll-behavior: smooth
  overflow: hidden

  &:before
    content: url(/static/img/body-background.png) url(/static/img/section-decoration.png)
    position: absolute
    width: 0
    height: 0
    overflow: hidden
    z-index: -1

  &:after
    content: ''
    position: absolute
    top: -24px
    left: 0
    width: 100%
    height: 100%
    background-image: url(/static/img/body-background.png)
    background-size: 100% auto
    background-repeat: no-repeat
    background-position: center top
    z-index: -1
    user-select: none
    pointer-events: none
    filter: blur(24px)

  +media(s)
    background-image: linear-gradient(180deg, #000E47, $dark1 calc(100% - 192px))

.body--without-background
  background-color: #08081B
  background-image: none

  &:before,
  &:after
    display: none

.main
  display: flex
  flex-direction: column
  width: 100%
  max-width: 100%
  padding-top: 100px
  padding-bottom: 104px
  overflow: hidden
  &:after
    content: ''
    position: absolute
    background-image: url(/static/img/section-decoration.png)
    pointer-events: none
    user-select: none
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    filter: blur(100px)
    top: 100%
    left: 50%
    width: 1550px
    height: 1550px
    transform: translate(-45%, -204px) rotate(20deg)
    z-index: 2
    


  +media(s)
    padding-top: 70px
    padding-bottom: 192px

    &:after
      width: 455px
      height: 455px
      transform: translate(-45%, -292px) rotate(20deg)

.main--home
  &:after
    background-image: url(/static/img/section-home-decoration.png)
    position: absolute
    top: auto
    bottom: 0
    left: 0
    height: 1128px
    width: 100%
    z-index: -1
    background-size: cover
    filter: none
    transform: none
    +media(s)
      background-image: url(/static/img/section-home-decoration-mobile.png)

html.burger-active,
.body.burger-active
  overflow-y: hidden

  .section,
  .footer
    // opacity: 0
    filter: blur(25px)

  .header
    backdrop-filter: blur(0px)

.title
  font-family: 'Geomanist', 'Inter', sans-serif
  text-align: center
  font-weight: 400
  color: $white

.text
  font-size: 16px
  line-height: 20px
  color: $white

.text--blue
  color: $dolphin

.container
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  max-width: 1220px
  padding-left: 20px
  padding-right: 20px
  margin: 0 auto

  +media(l)
    max-width: 100%

.container--big
  max-width: 1400px

.section
  display: flex
  position: relative
  justify-content: center
  opacity: 1
  transition: $all

.section--decoration

  &:before,
  &:after
    content: ''
    position: absolute
    background-image: url(/static/img/section-decoration.png)
    pointer-events: none
    user-select: none
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    filter: blur(100px)
    z-index: -1

.section--dark
  background-color: $dark1
  color: $white

.section--rounded-top
  border-top-left-radius: 20px
  border-top-right-radius: 20px
