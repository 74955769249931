.section--image
  margin-top: 529px
  position: relative
  +media(s)
  margin-top: 540px
  img
    width: 100%
    border: #0cc1fb 2px solid
    border-radius: 20px
  
    +media(s)
      width: 156%
