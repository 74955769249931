.filters
  display: flex
  width: 100%

  +media(s)
    flex-direction: column

.filters__label
  margin-right: 38px
  font-size: 16px
  line-height: 20px
  color: $white

  +media(s)
    margin-right: 0
    margin-bottom: 16px

.filters__list
  display: flex
  flex-wrap: wrap
  column-gap: 10px
  row-gap: 10px
  width: fit-content
