.section--page-article
  margin-top: 125px

  +media(s)
    margin-top: 36px

.page-article
  display: flex
  flex-direction: column
  width: 100%
  max-width: 780px
  padding: 54px 0

  +media(s)
    max-width: 100%
    padding: 32px 0


.page-article__navigation
  display: flex
  align-items: center
  width: 100%

.page-article__summary
  padding: 38px 0 34px
  border-bottom: 1px solid #{$white}33

  +media(s)
    padding: 24px 0

.page-article .article
  max-width: 100%

.page-article .article__title
  font-size: 70px
  line-height: 70px

  +media(s)
    font-size: 32px
    line-height: 32px

.page-article .article__text
  margin-top: 12px
  font-size: 20px
  line-height: 32px
  opacity: 1

  +media(s)
    font-size: 16px
    line-height: 24px

.page-article .article__footer
  margin-top: 34px

  +media(s)
    margin-top: 16px

.page-article__content
  margin-top: 30px
  margin-bottom: 62px
  font-size: 16px
  line-height: 1.625em
  color: $white
  width: 100%
  max-width: 680px
  margin-left: auto
  overflow: hidden

  +media(m)
    max-width: 100%
    margin-left: unset
    margin-bottom: 32px 

.page-article__content

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p
    line-height: 1.375em
    margin-bottom: 10px

  h1
    font-size: 36px

  h2
    font-size: 30px

  h3
    font-size: 24px

  h4
    font-size: 18px

  p + p
    margin-top: 22px

  a
    color: $blue-start
    transition: $all

    &:hover,
    &:focus
      color: $white

  ul,
  ol
    list-style-position: inside

  ol
    list-style-type: decimal

  ul
    list-style-type: disc

  hr
    margin: 40px 0
    width: 100%
    background-color: $white
    opacity: 0.05

    +media(s)
      margin: 24px 0

  img
    width: 100%
    object-fit: contain
    border-radius: 20px
    margin-top: 0px
    margin-bottom: 0px

.page-article__authors
  padding-top: 50px
  border-top: 1px solid #{$white}33

  +media(s)
    padding-top: 32px
