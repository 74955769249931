.section--details
  padding-top: 96px
  background: #08081B

  +media(s)
    padding-top: 47px

  .details
    display: flex
    flex-direction: column
    gap: 130px
    +media(s)
      gap: 80px

    .detail
      display: flex
      align-items: center
      gap: 80px
      flex: 1
      +media(l)
        gap: 40px
      +media(m)
        flex-direction: column
        gap: 30px

      img
        width: 100%
        height: 100%
        max-width: 550px
        max-height: 435px
        object-fit: contain
      .detail-content
        display: flex
        flex-direction: column
        gap: 16px
        flex: 1
        +media(m)
          padding: 0 80px

        +media(s)
          padding: 0 0px
        a
          margin-top: 16px
          height: 60px
        &__title
          font-weight: 500
          font-size: 18px
          line-height: 1.22em
          color: #00BDFF

        &__header
          font-weight: 400
          font-size: 54px
          line-height: 1em
          color: #FFFFFF
          font-family: Geomanist,Inter,sans-serif
          +media(s)
            font-size: 40px
            line-height: 40px

          .blue
            color: #00BDFF
        &__check-container
          display: flex
          gap: 16px
          font-size: 16px
          line-height: 1.45em
          color: #A2BCD4
          .check
            max-width: 24px
            width: 100%
            height: 24px
            display: flex
            justify-content: center
            align-items: center
            border-radius: 50%
            background: #171B33
          .example
            +media(s)
              font-family: Inter
              font-size: 14px
              font-weight: 400
              line-height: 20px
              letter-spacing: 0em
              text-align: left
              color: #A2BCD4
      .detail-content--order
        +media(s)
          order: 1
      .image-order
        +media(m)
          order: 2
