.button
  display: flex
  justify-content: center
  align-items: center
  column-gap: 10px
  width: fit-content
  min-width: 150px
  height: 60px
  padding: 12px 30px
  border-radius: 16px
  text-align: center
  font-size: 16px
  font-weight: 600
  line-height: 24px
  transition: $all

  &:active
    transform: scale(0.95)

.button--primary
  color: $dark1
  background-image: $button-gradient

  &:hover,
  &:focus
    color: $dark1
    box-shadow: 0px 0px 45px #{$blue-start}66

.button--secondary
  height: 50px
  background: linear-gradient(#08081B, #08081B) padding-box padding-box, linear-gradient(to right, #37DCF2, #07C0FB) border-box border-box
  border: 2px solid transparent
  color: $white
  padding: 16px 20px

  &:hover,
  &:focus
    color: $white
    filter: drop-shadow(0px 4px 45px rgba(16, 197, 249, 0.45))
    .arrow
      transform: translateX(8px)

.button--secondary .arrow
  transition: transform 0.25s ease
