.section--hero
  width: 100%
  position: fixed
  // height: 1018px
  padding-bottom: 354px
  margin-inline: auto
  margin-top: 0px

.section--hidden
  opacity: 0
  visibility: hidden
  pointer-events: none
.hero
  align-items: center
  display: flex
  flex-direction: column
  text-align: center
  width: 100%
  +media(s)
    .icon
      width: 141px

  &:after
    content: ''
    background-image: url(/static/img/hero-section-decoration.png)
    position: absolute
    top: auto
    bottom: 0
    left: 0
    height: 1018px
    width: 100%
    z-index: -1
    background-size: cover
    filter: none
    transform: none
    +media(s)
      background-image: url(/static/img/hero-section-decoration-mobile.png)
      height: 426px
      bottom: 246px

.hero__title
  font-size: 70px
  line-height: 70px

  +media(m)
    font-size: 50px
    line-height: 50px
  +media(s)
    max-width: 280px
    font-size: 40px
    line-height: 40px
    margin-top: -20px

.hero__text
  max-width: 703px
  margin-top: 32px
  font-size: 16px
  line-height: 22px
  color: #B0CFEB
  text-align: center

  +media(s)
    max-width: 328px
    font-family: Inter
    font-size: 14px
    font-weight: 400
    line-height: 20px
    letter-spacing: 0em
    text-align: center

.hero__cta
  margin: 44px auto 0

  img
    width: 24px
    height: 24px

  +media(s)
    margin: 24px auto 0
    height: 56px
    min-width: 191px
