.section--users
    margin-top: 147px
    flex-direction: column
    +media(s)
        margin-top: 112px

    .button--secondary
        +media(s)
            background: rgba(10,14,39,.4)
            backdrop-filter: blur(25px)
            border: 1px solid #07C0FB

.defi-users__title
    font-family: Geomanist
    font-size: 40px
    font-weight: 400
    line-height: 40px
    letter-spacing: -0.01em
    text-align: center
    color: white
    margin-bottom: 40px
    +media(s)
        margin-bottom: 24px

.defi-users__container
    display: flex
    gap: 20px
    width: 100%
    +media(s)
        flex-direction: column
        gap: 32px

.defi-users__container-box-left
    height: 100%
    width: 100%

.defi-users__container-box-right
    display: flex
    flex-direction: column
    gap: 21px
    max-width: 680px
    width: 100%
.box-dark__text
    display: flex
    flex-direction: column
.box-dark
    display: flex
    flex-direction: column
    justify-content: space-between
    position: relative
    width: 100%
    border: 1px solid #1E2239
    backdrop-filter: blur(12.5px)
    border-radius: 20px
    padding: 32px 36px 24px 32px
    background: #0B0E22
    contain: paint
    a
        height: 60px
        background: rgba(10, 14, 39, 0.4)
        backdrop-filter: blur(25px)
        border: 2px solid #07c0fb
    +media(s)
        padding: 22px 25px 16px 22px
    span
        font-size: 16px
        font-weight: 500
        line-height: 19px
        letter-spacing: 0em
        text-align: left
        color: #00BDFF
        margin-bottom: 16px
        +media(s)
            font-size: 14px
            line-height: 17px
            margin-bottom: 11px

    div
        font-family: Geomanist
        font-size: 40px
        font-weight: 400
        line-height: 40px
        letter-spacing: -0.01em
        text-align: left
        margin-bottom: 12px
        +media(s)
            font-size: 32px
            line-height: 32px
            margin-bottom: 8px

    p
        font-family: Inter
        font-size: 16px
        font-weight: 400
        line-height: 23px
        letter-spacing: 0em
        text-align: left
        color: #A2BCD4
        +media(s)
            font-size: 14px
            line-height: 16px

.detail-content__check-container .check
    max-width: 24px
    width: 100%
    height: 24px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50%
    background: #171B33

.detail-content__check-container
    display: flex
    flex-direction: row
    gap: 16px

.detail-content__check-container .example
    font-family: Inter
    font-size: 14px
    font-weight: 400
    line-height: 20px
    letter-spacing: 0em
    text-align: left
    color: #A2BCD4

.box-dark__text-title
    max-width: 221px
.box-dark__text-description
    max-width: 300px
.box-dark--left
    min-height: 646px
    justify-content: flex-end

    +media(s)
        min-height: 580px
.box-dark--right-top , .box-dark--right-bottom
    min-height: 313px
    +media(s)
        min-height: 462px

.img-down
    top: 0
    left: 0
    width: 100%
    object-fit: cover
    position: absolute
    height: 50%
    z-index: -1
    // +media(s)
    //     max-width: 333px

.img-right
    position: absolute
    right: 0
    bottom: 0
    height: 100%
    object-fit: cover
    z-index: -1
    border-top-right-radius: 20px
    border-bottom-right-radius: 20px
    +media(s)
        display: none

.img-right-mobile
    display: none
    +media(s)
        display: block
        position: absolute
        right: 0
        bottom: 0
        object-fit: cover
        z-index: -1
        width: 100%
        max-height: 317px
