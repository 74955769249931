$shell: #00BDFF
$adriatic: #3BD5D7
$algea: #7ADEB9
$pacific: #A9D1E2
$dolphin: #87C0DD

$dark1: #0A0E27
$dark2: #171B33
$dark3: #1E2239
$dark4: #151530
$dark5: #141B44

$gray-1: #F0F2F6
$gray-2: #E6E8F0
$gray-3: #7D7D97
$gray-4: #464659
$gray-5: #292941

$white: #ffffff
$blue-start: #37DCF2
$blue-end: #07C0FB
$button-gradient: linear-gradient(90deg, $blue-start, $blue-end)
$card-border: linear-gradient(135deg, #0696D966, #17357B66, #0C73B866)
$all: all 0.3s ease-in-out
