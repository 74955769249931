.footer
	display: flex
	position: absolute
	bottom: 0
	align-items: center
	width: 100%
	height: 104px
	padding: 0 0 46px

	+media(s)
		height: 192px
		padding: 0 0 40px

.footer__copyrights
	display: flex
	flex-direction: column

	+media(s)
		margin: 0 auto
		align-items: center
		margin-bottom: 16px

.footer__logo
	width: 100%
	max-width: 22px
	height: auto
	object-fit: contain

	+media(s)
		max-width: 22px

.footer__copyrights-text
	margin-top: 16px
	font-size: 12px
	line-height: 14px
	font-weight: 400
	opacity: 1

	+media(s)
		color: #464659
		margin-top: 16px
		text-align: center

.footer__menu
	display: flex
	justify-content: space-between
	align-items: center
	width: 100%

	+media(s)
		justify-content: center

.footer__list
	display: flex
	align-items: center
	column-gap: 10px
	row-gap: 10px
	width: fit-content

	+media(s)
		position: absolute
		margin-top: 140px

.footer__item
	display: flex
	justify-content: center
	align-items: center
	width: fit-content

.footer__link
	width: 40px
	height: 40px

.footer__link-icon
	width: 100%
	max-width: 16px
	height: auto
	object-fit: contain
